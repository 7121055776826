import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import axios from 'axios';

import { Provider } from "react-redux";
import { createStore } from "redux";
import rootReducer from "./store/reducers/"


axios.defaults.withCredentials = true;
const store = createStore(rootReducer);

ReactDOM.render(
  <Provider store={store} >
    <UserProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </UserProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
