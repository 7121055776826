import React, { useState, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/common/Header';
import MainPage from './pages/MainPage';
import NoticePage from './pages/NoticePage';
import ProductPage from './pages/ProductPage';
import ProductDetailPage from './pages/ProductDetailPage';
import MyPage from './pages/MyPage';
import OrderPage from './pages/OrderPage';
import Footer from './components/common/Footer';
import JoinConfirmPage from './pages/JoinConfirmPage';
import LoginPage from './pages/LoginPage';
import JoinPage from './pages/JoinPage';
import { useUserState } from './contexts/UserContext';

const Main = styled.div`
  width: 80%;
  margin: 0 auto;
  min-height: calc(100vh - 14rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 60px;
`;

const App = () => {
  const { user } = useUserState();
  const [menu, setMenu] = useState("Main");
  const onSelect = useCallback((menu) => setMenu(menu), []);

  return (
    <>
      {
        user ? (
          <>
            <Header menu={menu} onSelect={onSelect} loggedIn="true" />
            <Main>
              <Routes>
                <Route exact path="/" element={<MainPage />} />
                <Route exact path="/notice" element={<NoticePage />} />
                <Route exact path="/product" element={<ProductPage />} />
                <Route exact path="/product/detail/:productId" element={<ProductDetailPage />} />
                <Route exact path="/mypage" element={<MyPage />} />
                <Route exact path="/order" element={<OrderPage onSelect={onSelect} />} />
              </Routes>
            </Main>
          </>
        ) : (
          <>
            <Header menu={menu} onSelect={onSelect} />
            <Main>
              <Routes>
                <Route exact path="/" element={<MainPage />} />
                <Route exact path="/login" element={<LoginPage />} />
                <Route exact path="/join" element={<JoinPage />} />
                <Route exact path="/join/confirm" element={<JoinConfirmPage />} />
              </Routes>
            </Main>
          </>
        )
      }
      < Footer />
    </>
  )
}

export default App;