import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import Counter from '../components/common/Counter';
import Button from '../components/common/Button';
import Toast from '../components/common/Toast';
import { useDispatch } from 'react-redux';
import { useUserState } from '../contexts/UserContext';
import { addCart, deleteCart } from '../store/actions';

const Wrapper = styled.div`
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 80px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Container = styled.div`
  /* width: 510px; */
  display: flex;
  flex-direction: column;
`;

const ButtonBox = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const Title = styled.div`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 25px;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
`;

const Detail = styled.div`
  height: 180px;
  background-color: #eeeeee;
  margin: 10px 0 10px 0;
  border-radius: 10px;
  padding: 10px;
`;

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const { user } = useUserState();

  const [product, setProduct] = useState({});
  const { productId } = useParams();

  const getDetailById = async (id) => {
    try {
      const { data } = await axios.get(`/api/products/productlist/${id}`);
      data.count = 1;
      setProduct(data);
    } catch (e) {
      console.log(e);
    }
  };

  const [toast, setToast] = useState(false);
  const _handleClick = () => {
    setToast(!toast);
  };
  const _handleDelete = () => {
    dispatch(deleteCart(product));
  };

  const [count, setCount] = useState(1);
  const _handleChange = (count) => {
    setCount(count);
    setProduct({ ...product, count: count });
  };

  useEffect(() => {
    getDetailById(productId);
  }, [productId]);

  return (
    <>
      <Wrapper>
        <img
          src={product.img}
          alt="thumbnail"
          style={{
            marginRight: '20px',
            width: '400px',
            height: '400px',
            border: '1px solid gray',
          }}
        />
        <Container>
          <Title>{product.product_name}</Title>
          <Text style={{ color: 'gray', marginBottom: '26px' }}>
            {product.main_category} | {product.sub_category}
          </Text>
          <Detail>{product.description}</Detail>
          <RowWrapper style={{ justifyContent: 'space-between' }}>
            <RowWrapper>
              <Text style={{ marginRight: '10px' }}>수량</Text>
              <Counter count={count} onChange={_handleChange} />
            </RowWrapper>
            <RowWrapper>
              <Text style={{ marginRight: '10px' }}>총 금액</Text>
              <Text style={{ fontSize: '20px' }}>
                {(count * product[user.level]).toLocaleString('ko-KR')}
              </Text>
            </RowWrapper>
          </RowWrapper>
          <ButtonBox>
            <Button
              width="510"
              onClick={() => {
                _handleClick();
                dispatch(addCart(user.level, product, _handleDelete));
              }}
            >
              장바구니 담기
            </Button>
          </ButtonBox>
        </Container>
      </Wrapper>
      {toast && (
        <Toast _handleToast={_handleClick}>제품이 장바구니에 담겼습니다</Toast>
      )}
    </>
  );
};

export default ProductDetailPage;
