/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import swal from 'sweetalert2';
import download from 'downloadjs';
import { useNavigate } from 'react-router-dom';
import { useUserState } from '../contexts/UserContext';
// import Pagination from 'react-bootstrap/Pagination';
import Button from '../components/common/Button';
import Table from '../components/common/Table';
import Loading from '../components/common/Loading';
import Pagination from '../components/common/Pagination';

const Box = styled.div`
  width: 70%;
  min-height: 70vh;
  box-shadow: 0px 0px 50px 20px rgba(138, 138, 138, 0.1);
  border-radius: 50px;
  padding: 20px 40px 20px 40px;
  margin-bottom: 30px; ;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 30px;
`;

const Left = styled.div`
  width: 30%;
  height: 240px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 40px;
`;

const Welcome = styled.div`
  font-weight: 700;
  font-size: 30px;
  margin-right: 20px;
`;

const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 5px;
`;

const DownloadButton = styled.button`
  display: inline-block;
  width: 80px;
  height: 30px;
  background-color: #eeeeee;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: none;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const MyPage = () => {
  const navigate = useNavigate();
  const { user } = useUserState();
  const [orders, setOrders] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState();
  const [pagination, setPagination] = useState(0);
  const [posts, setPosts] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get(`/api/orders/orderlist/${user._id}`);
      setPosts(response);
      setLoading(false);
    };
    fetchData();
  }, []);

  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const currentPosts = (posts) => {
    let currentPosts = 0;
    let idPosts = 0;
    idPosts = posts.data.id.slice(indexOfFirst, indexOfLast);
    currentPosts = posts.data.data.slice(indexOfFirst, indexOfLast);
    let newPosts = currentPosts.map((item, i) =>
      Object.assign({}, item, idPosts[i]),
    );
    newPosts.map((r, i) => {
      r.button = (
        <DownloadButton onClick={() => getUrl(r.id, r.orderId)}>
          다운로드
        </DownloadButton>
      );
    });
    let deleted = [];
    newPosts.map((r) => {
      deleted.push({
        orderId: r.orderId,
        time: r.time,
        postNum: r.postNum,
        button: r.button,
      });
    });
    return deleted;
  };
  const getUrl = async (id, orderId) => {
    try {
      const { data: res } = await axios.get(
        `/api/orders/orderlist/download/${user._id}/${id}`,
      );
      download(res, `슈퍼트랙_발주서_${orderId}.pdf`, 'application/pdf');
    } catch (e) {
      console.log(e);
    }
  };

  const getOrder = async (_id) => {
    try {
      setIsLoading(true);
      const { data: res } = await axios.get(`/api/orders/orderlist/${_id}`);
      res.data.map((r, i) => {
        r.button = (
          <DownloadButton onClick={() => getUrl(res.id[i].id, r.orderId)}>
            다운로드
          </DownloadButton>
        );
        return r;
      });
      setOrders(res.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getOrder(user._id);
  }, [user]);

  const _handleLogout = () => {
    swal
      .fire({
        text: '로그아웃 하시겠습니까?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#7499ab',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          try {
            localStorage.removeItem('user');
            navigate('/');
            setIsLoading(false);
            window.location.reload();
          } catch (e) {
            swal.fire({
              position: 'top-end',
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  const _handlePassword = async () => {
    const { value: pwd } = await swal.fire({
      title: '새로운 비밀번호를 입력해 주세요.',
      text: '변경 후에는 새로운 비밀번호로 재로그인이 필요합니다.',
      input: 'password',
      inputPlaceholder: '6자리 이상 입력',
      confirmButtonText: '확인',
      cancelButtonText: '취소',
      confirmButtonColor: '#232323',
      cancelButtonColor: '#a3a3a3',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return '입력된 값이 없습니다.';
        }
      },
    });
    if (pwd) {
      try {
        const res = await axios.patch(`/api/users/password/${user._id}`, {
          user_password: pwd,
        });
      } catch (err) {
        if (err.response.status === 400);
        alert('오류');
      }
      swal
        .fire({
          title: '변경이 완료되었습니다.',
          text: '재로그인을 위해 로그아웃됩니다.',
          icon: 'success',
          showConfirmButton: true,
          confirmButtonColor: '#232323',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              setIsLoading(true);
              localStorage.removeItem('user');
              navigate('/');
              setIsLoading(false);
              window.location.reload();
            } catch (e) {
              swal.fire({
                position: 'top-end',
                width: 400,
                text: '오류가 발생했습니다.',
                icon: 'error',
                showConfirmButton: false,
                timer: 1000,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          swal.fire({
            width: 400,
            title: '오류가 발생했습니다.',
            text: error,
            icon: 'error',
            showConfirmButton: false,
            timer: 1000,
          });
        });
    }
  };

  const titles = ['발주번호', '발주일', '운송장 번호', '발주서'];

  return (
    <>
      <Container>
        <Left>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Welcome>
              {user.manager_name} 님, <br />
              환영합니다.
            </Welcome>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <Text>{user.company_name}</Text>
            <Text>{user.user_email}</Text>
            <Text>{user.manager_contact}</Text>
          </div>
          <Button
            onClick={_handleLogout}
            width="120"
            height="80"
            size="15"
            style={{
              padding: '5px',
              border: '2px solid black',
              marginBottom: '5px',
            }}
          >
            로그아웃
          </Button>
          <Button
            onClick={_handlePassword}
            width="120"
            height="40"
            size="15"
            style={{ padding: '5px', border: '2px solid black' }}
          >
            비밀번호 변경
          </Button>
        </Left>
        <Box>
          {orders ? (
            <>
              <Table
                caption="발주 내역"
                titles={titles}
                data={currentPosts(posts)}
              />
              <div>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={posts.data.data.length}
                  paginate={setCurrentPage}
                ></Pagination>
              </div>
            </>
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>발주 내역이 없습니다.</p>
            </div>
          )}
        </Box>
      </Container>
      {isLoading && <Loading />}
    </>
  );
};

export default MyPage;
