import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import axios from 'axios';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 160px);
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 50px;
`;

const JoinPage = () => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    pwconfirm: '',
    company: '',
    manager: '',
    contact: '',
  });

  const { email, password, pwconfirm, company, manager, contact } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onPhoneChange = (e) => {
    const { value } = e.target;
    const onlyNumber = value.replace(/[^0-9]/g, '');
    setInputs({
      ...inputs,
      contact: onlyNumber
    })
  }

  const _handleJoin = async (e) => {
    e.preventDefault();
    if (inputs.password !== pwconfirm) {
      return alert('비밀번호가 다릅니다.');
    }

    const dataToSubmit = {
      manager_name: inputs.manager,
      company_name: inputs.company,
      user_email: inputs.email,
      user_password: inputs.password,
      manager_contact: inputs.contact,
    };

    try {
      const res = await axios.post('/api/users/register', dataToSubmit);
      navigate('/join/confirm');
    } catch (err) {
      if (err.response.status === 400);
      alert('이미 가입된 메일입니다.');
    }
  };

  return (
    <Container>
      <Text>JOIN</Text>
      <div style={{ display: 'flex' }}>
        <div style={{ margin: '20px' }}>
          <div
            style={{ fontSize: '14px', color: 'gray', marginBottom: '20px' }}
          >
            ※ 입력하신 이메일은 로그인 시 아이디로 사용되며, 변경이
            불가능합니다.
          </div>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={onChange}
            placeholder="EMAIL"
          />
          <Input
            type="password"
            name="password"
            value={password}
            onChange={onChange}
            placeholder="PASSWORD"
          />
          <Input
            type="password"
            name="pwconfirm"
            value={pwconfirm}
            onChange={onChange}
            placeholder="PASSWORD CONFIRM"
          />
        </div>
        <div style={{ margin: '20px' }}>
          <div
            style={{ fontSize: '14px', color: 'gray', marginBottom: '20px' }}
          >
            <br />
          </div>
          <Input
            type="text"
            name="company"
            value={company}
            onChange={onChange}
            placeholder="회사명"
          />
          <Input
            type="text"
            name="manager"
            value={manager}
            onChange={onChange}
            placeholder="담당자 이름"
          />
          <Input
            type="num"
            name="contact"
            value={contact}
            onChange={onPhoneChange}
            placeholder="담당자 연락처"
          />
        </div>
      </div>
      <Button
        width="400"
        onClick={_handleJoin}
        style={{ backgroundColor: 'black', color: 'white' }}
      >
        JOIN
      </Button>
      <Link to="/login">
        <Button width="400" style={{ marginTop: '10px' }}>
          BACK
        </Button>
      </Link>
    </Container>
  );
};

export default JoinPage;
