import React, { useState } from 'react';
import styled from 'styled-components';
import { BiChevronDown } from 'react-icons/bi';
import { MdOutlineShoppingCart } from 'react-icons/md';
import CartModal from './CartModal';

const Side = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

const Cart = styled.div`
  width: 250px;
  height: 40px;
  padding: 15px;
  margin-top: 10px;
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Category = styled.div`
  width: 250px;
  height: 40px;
  padding: 15px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 15px;
`;

const SubText = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #525252;
  margin-left: 30px;
  margin-bottom: 10px;
  cursor: pointer;
`;

const ProductSidebar = ({ category, onSelect }) => {
  const [display, setDisplay] = useState([false, false, false]);
  const _handleDisplay = (index) => {
    let newDisplay = [...display];
    newDisplay[index] = !newDisplay[index];
    setDisplay(newDisplay);
  };

  // let i = 0;
  // let count = [];
  // do {
  //   count.push(false);
  // } while (i < category.length);
  // setDisplay(count);

  const [openModal, setOpenModal] = useState(false);
  const _handleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Side>
        <Cart onClick={_handleModal} _handleModal={_handleModal}>
          <Text>장바구니</Text>
          <MdOutlineShoppingCart />
        </Cart>
        <Category onClick={() => onSelect('all')}>
          <Text>전체 보기</Text>
        </Category>
        {category &&
          category.map((c, index) => (
            <>
              <Category onClick={() => _handleDisplay(index)}>
                <Text>{c.main_category}</Text>
                <BiChevronDown />
              </Category>
              <div>
                {display[index] ? (
                  c.sub_category.map((text) => (
                    <SubText onClick={() => onSelect(text)}>{text}</SubText>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </>
          ))}
      </Side>
      {openModal && <CartModal _handleModal={_handleModal} />}
    </>
  );
};

export default ProductSidebar;
