import { Font, StyleSheet } from '@react-pdf/renderer'
Font.register({
  family: 'SpoqaHanSans',
  src:
	'https://b2b.supertrack.co.kr/SpoqaHanSansLight.ttf'
});

export const pdfStyle = StyleSheet.create({
  page: {
    fontFamily: 'SpoqaHanSans',
  },
  container: {
    margin: "20px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    marginBottom: '20px',
    padding: '30px',
    fontSize: '12px',
    overflow: 'visible'
  },
  title_text: {
    fontWeight: '800',
    fontSize: '30px',
    marginBottom: '30px'
  },
  line_wrapper_top: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    border: "1px solid black"
  },
  line_wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    borderBottom: "1px solid black"
  },
  line_container: {
    paddingTop: "3px",
    paddingBottom: "3px",
    display: "flex",
    justifyContents: "center",
    alignItems: "center",
    borderRight: "1px solid black",
  },
  line_container_right: {
    paddingTop: "3px",
    paddingBottom: "3px",
    display: "flex",
    justifyContents: "center",
    alignItems: "center",
  }
})
