import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableContainer = styled.table`
  border: none;
  border-collapse: collapse;
  td,
  th {
    text-align: center;
    border: none;
    padding: 5px 10px;
  }
  tbody tr {
    height: 50px;
  }
  thead > tr {
    background-color: #eeeeee;
    height: 50px;
  }
`;

const Table = ({ caption, titles, data }) => {
  let titlesIdx = [];
  if (data) titlesIdx = Object.keys(data[0]);
  return (
    <Container>
      <h2>{caption}</h2>
      {data ? (
        <TableContainer>
          <thead>
            <tr>
              {titles.map((title, index) => {
                return <th key={index}>{title}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {titlesIdx.map((title, index) => {
                  if (title === 'price') {
                    return (
                      <td key={index}>{item[title].toLocaleString('ko-KR')}</td>
                    );
                  } else {
                    return <td key={index}>{item[title]}</td>;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </TableContainer>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Table;
