import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const Wrapper = styled.div`
  position: absolute;
  bottom: 100px;
  margin-top: 0px;
  height: 50px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eeeeee;
`;

const Bold = styled.div`
  margin-left: 10px;
  font-weight: 600;
`;

const Normal = styled.div`
  font-weight: 500;
  margin-left: 20px;
`;

const CartCal = () => {
  const cart = useSelector((store) => store.cartReducer);
  const [sum, setSum] = useState(0);
  useEffect(() => {
    let s = 0;
    cart.forEach((item) => {
      s += item.price;
    });
    setSum(s);
  }, [cart]);

  return (
    <Wrapper>
      <Bold>총 상품 가격</Bold>
      <Normal>{sum.toLocaleString('ko-KR')}</Normal>
    </Wrapper>
  );
};

export default CartCal;
