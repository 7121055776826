import React, { useState, useEffect } from 'react';
import Preview from '../components/pdf/Preview';
import Input from '../components/common/Input';
import TextBox from '../components/common/TextBox';
import { useUserState } from '../contexts/UserContext';
import { useSelector } from 'react-redux';
import axios from 'axios';

const OrderPage = ({ onSelect }) => {
  const { user } = useUserState();
  const cartStore = useSelector((store) => store.cartReducer);
  let cart = cartStore;
  const [sum, setSum] = useState(0);
  const [shipping, setShipping] = useState(0);

  const getShipping = async () => {
    const { data: res } = await axios.get('api/shipping/price');
    setShipping(Number(res));
  }
  useEffect(() => {
    let s = 0;
    cart &&
      cart.forEach((item) => {
        s += item.price;
      });
    setSum(s);
    getShipping();
  }, [cart]);

  const [inputs, setinputs] = useState({
    type: 'inputs',
    name: `${user.manager_name}`,
    display: true,
    phoneNo: `${user.manager_contact}`,
    address: '',
    other: '',
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setinputs({ ...inputs, [name]: value });
  };

  return (
    <div
      style={{
        width: '100%',
        height: '85vh',
        display: 'flex',
        paddingTop: '20px',
      }}
    >
      <div style={{ width: '500px', marginRight: '30px', marginTop: '80px' }}>
        <div style={{ color: 'gray', fontSize: '12px', marginBottom: '10px' }}>
          ※ 아래 내용이 입력되면 한글이 제대로 렌더링 됩니다
        </div>
        <Input
          type="name"
          name="name"
          value={inputs.name}
          onChange={handleChange}
          placeholder="수령인"
        />
        <Input
          name="phoneNo"
          value={inputs.phoneNo}
          onChange={handleChange}
          placeholder="연락처"
        />
        <Input
          name="address"
          value={inputs.address}
          onChange={handleChange}
          placeholder="주소"
        />
        <TextBox
          name="other"
          value={inputs.other}
          onChange={handleChange}
          placeholder="비고"
          height="180px"
        />
      </div>
      <Preview inputs={inputs} onSelect={onSelect} cart={cart} sum={sum} shipping={shipping} />
    </div>
  );
};

export default OrderPage;
