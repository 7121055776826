import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { BiX } from 'react-icons/bi';
import Button from '../common/Button';
import CartCal from './CartCal';
import { useSelector } from 'react-redux';
import Table from '../common/Table';

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BottomSafe = styled.div`
  height: 150px;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  animation: modal-bg-show 0.5s;
  @keyframes modal-bg-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ModalBlock = styled.div`
  position: absolute;
  top: 60px;
  border-radius: 10px;
  padding: 40px;
  background-color: white;
  width: 80%;
  min-height: 600px;
  animation: modal-show 0.5s;
  @keyframes modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Close = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  cursor: pointer;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const CartModal = ({ _handleModal, children, ...rest }) => {
  // _handleModal로 modal open/close 되는 토글 함수 넣어 주면 됨
  /* style, className, onClick, onMouseMove 등의 props를 사용할 수 있도록 */
  /* ...rest를 사용하여 ModalFrame에게 전달 */
  const cart = useSelector((store) => store.cartReducer);
  const titles = ['제품', '수량', '가격', '삭제'];

  return (
    <Container>
      <Background onClick={_handleModal} />
      <ModalBlock {...rest}>
        <Close onClick={_handleModal}>
          <BiX size="40" />
        </Close>
        <Contents>
          {cart.length === 0 ? (
            <div>
              <h3 style={{ marginTop: '250px' }}>장바구니가 비어 있습니다.</h3>
            </div>
          ) : (
            <>
              <Table caption="장바구니" titles={titles} data={cart} />
              <BottomSafe />
              <CartCal />
              <Link
                to="/order"
                style={{ position: 'absolute', bottom: '40px' }}
              >
                <Button>발주서 생성하기</Button>
              </Link>
            </>
          )}
        </Contents>
      </ModalBlock>
    </Container>
  );
};

export default CartModal;
