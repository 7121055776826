import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Button from '../components/common/Button';
import axios from 'axios';

const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
`;

const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  position: absolute;
  top: 270px;
  right: 80px;
  width: 600px;
`;

const Welcome = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
`;

const WelcomeText = styled.div`
  font-size: 70px;
  font-weight: 800;
  z-index: 10;
`;

const MobileWelcome = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MobileWelcomeText = styled.div`
  font-size: 50px;
  font-weight: 800;
`;

const MainPage = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 767px)"
  });
  const [image, setImage] = useState();

  const getUrl = async () => {
    try {
      const { data: url } = await axios.get(
        `/api/image/main`,
      );
      setImage(url);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUrl();
  }, []);

  return (
    <>
      {isMobile ? (
        <MobileWrapper>
          <MobileWelcome>
            <MobileWelcomeText>Welcome to</MobileWelcomeText>
            <MobileWelcomeText>Supertrack</MobileWelcomeText>
            <MobileWelcomeText>Partners!</MobileWelcomeText>
          </MobileWelcome>
        </MobileWrapper>
      ) : (
        <Wrapper>
          <Welcome>
            <WelcomeText>Welcome to</WelcomeText>
            <WelcomeText>Supertrack</WelcomeText>
            <WelcomeText>Partners!</WelcomeText>
            <Button
              href="mailto:ceo@supertrack.co.kr"
              style={{ marginTop: "40px", fontWeight: "800" }}>
              파트너 신청 메일 보내기
            </Button>
          </Welcome>
          <Image
            src={image}
            alt="image"
          />
        </Wrapper>
      )}
    </>
  )
}

export default MainPage;