import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NoticePage = () => {
  const [image, setImage] = useState();

  const getUrl = async () => {
    try {
      const { data: url } = await axios.get(
        `/api/image/notice`,
      );
      setImage(url);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUrl();
  }, []);
  return (
    <div style={{ width: "100%" }}>
      <h1>NOTICE</h1>
      <img src={image} alt="notice" style={{ width: "900px" }} />
    </div>
  )
}

export default NoticePage;