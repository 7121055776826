// store -> reducers -> cartReducer.js

const cartReducer = (state = [], action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      let result = [...state, action.payload];
      if (state.length !== 0) {
        state.map((s) => {
          if (s.product_name === action.payload.product_name) {
            s.count = s.count + action.payload.count;
            s.price = s.price + action.payload.price;
            result = [...state];
          }
        });
      }
      return result;
    case 'DELETE_ITEM':
      let filtered = state.filter(
        (item) => item.product_name !== action.payload.product_name,
      );
      return filtered;
    default:
      return state;
  }
};

export default cartReducer;
