import React from 'react';
import styled from 'styled-components';
import ProductContainer from './ProductContainer';

const Wrapper = styled.div`
  display: grid;
  width: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 30px;
`;

const ProductList = ({ products, select, level }) => {
  return (
    <Wrapper>
      {products &&
        products.map((product) =>
          <ProductContainer
            key={product._id}
            product={product}
            level={level}
          />
        )
      }
    </Wrapper>
  );
}

export default ProductList;