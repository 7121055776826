import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 80%;
  margin: 0 auto; /* 중앙 정렬 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #8a8a8a;
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <div>
          <Text>TEL : 02-539-7778</Text>
          <Text>EMAIL : ceo@supertrack.co.kr</Text>
        </div>
        <Text>COPYRIGHT© SUPERTRACK. ALL RIGHTS RESERVED.</Text>
      </Container>
    </Wrapper>
  );
};

export default Footer;