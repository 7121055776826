import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import ProductSidebar from '../components/product/ProductSidebar';
import Searchbar from '../components/common/Searchbar';
import ProductList from '../components/product/ProductList';
import { useUserState } from '../contexts/UserContext';
import Loading from '../components/common/Loading';

const Wrapper = styled.div`
  padding: 50px 0px 50px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ProductPage = () => {
  const { user } = useUserState();
  const getProducts = async () => {
    setIsLoading(true);
    try {
      const { data: res } = await axios.get('/api/products/productlist');
      setProducts(res);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      const { data: res } = await axios.get('/api/products/category');
      let arr;
      res.map((manu) => {
        arr = [];
        if (!Array.isArray(manu.sub_category)) {
          arr.push(manu.sub_category);
          manu.sub_category = arr;
        }
      });
      setCategory(res);
    } catch (error) {
      console.log(error);
    }
  };

  const _handleSelect = async (category) => {
    if (category === 'all') {
      getProducts();
    } else {
      try {
        setIsLoading(true);
        const { data: res } = await axios.get('/api/products/productlist');
        const selected = res.filter(
          (product) => product.sub_category === category,
        );
        setProducts(selected);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const _handleChange = (search) => {
    setSearchField(search.toLowerCase());
  };

  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    getProducts();
    getCategory();
  }, []);

  useEffect(() => {
    setFilteredProducts(
      () =>
        products &&
        products.filter((product) =>
          product.product_name.toLowerCase().includes(searchField),
        ),
    );
  }, [searchField, products]);

  return (
    <Wrapper>
      {isLoading && <Loading />}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Searchbar onChange={_handleChange} />
        <ProductSidebar category={category} onSelect={_handleSelect} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        {filteredProducts && (
          <ProductList products={filteredProducts} level={user.level} />
        )}
      </div>
    </Wrapper>
  );
};

export default ProductPage;
