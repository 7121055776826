// store -> actions -> index.js
import { BiTrash } from 'react-icons/bi';

export const addCart = (level, item, _handleClick) => {
  let filtered = {};
  filtered.product_name = item.product_name;
  filtered.count = item.count ? item.count : 1;
  filtered.price = item.count ? item.count * item[level] : item[level];
  filtered.select = (
    <BiTrash
      size={20}
      onClick={() => _handleClick()}
      style={{ cursor: 'pointer' }}
    />
  );
  return {
    type: 'ADD_ITEM',
    payload: filtered,
  };
};
// type 이라는 속성을 가진 액션을 생성하는 addCart 는 액션 생성 함수입니다.
// 이름만 봐도 장바구니에 담는 역할을 할 것 같습니다.

export const deleteCart = (item) => {
  return {
    type: 'DELETE_ITEM',
    payload: item,
  };
};
