import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: transparent;
`;

const ToastBlock = styled.div`
padding: 20px;
  background-color: black;
  color: white;
  font-weight: 700;
  box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.1);
  position: absolute;
  width: 300px;
  bottom: -70px;
  right: 0px;
  border-radius: 10px;
  margin: 10px;
  animation: toast-show 1s alternate 2;
  @keyframes toast-show {
    from {
      opacity: 0;
      bottom: -70px;
    }
    to {
      opacity: 1;
      bottom: 0px;
    }
  }
`;

const Contents = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Toast = ({ _handleToast, children, ...rest }) => {
  return (
    <Container>
      <Background onClick={_handleToast} />
      <ToastBlock {...rest}>
        <Contents>
          {children}
        </Contents>
      </ToastBlock>
    </Container>
  );
};

export default Toast;