import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MdOutlineShoppingCart } from 'react-icons/md';
import swal from 'sweetalert2';
import CartModal from '../product/CartModal';

const userMenus = [
  { name: 'NOTICE', link: '/notice' },
  { name: 'PRODUCT', link: '/product' },
  { name: 'MYPAGE', link: '/mypage' },
];

const noUserMenus = [
  { name: 'LOGIN', link: '/login' }
];

const Logo = styled.div`
  font-weight: 700;
  font-size: 25px;
`;

const SubLogo = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const LogoBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: #8a8a8a;
  ${props => props.active && css`
    color: #000;
  `}
  margin-right: 30px;
`;

const MenusBlock = styled.div`
  display: flex;
`;


const HeaderBlock = styled.div`
  position: fixed;
  width: 100%;
  background: #eeeeee;
  z-index: 999;
`;

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto; /* 중앙 정렬 */
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Spacer = styled.div`
  height: 100px;
`;

const Header = ({ menu, onSelect, loggedIn }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const _handleModal = () => {
    setOpenModal(!openModal);
  };
  const _handleLogout = () => {
    swal
      .fire({
        text: '로그아웃 하시겠습니까?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#7499ab',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            localStorage.removeItem('user');
            navigate('/');
            window.location.reload();
          } catch (e) {
            swal.fire({
              position: 'top-end',
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };
  return (
    <>
      {
        loggedIn ? (
          <>
            <HeaderBlock>
              <Wrapper>
                <LogoBlock>
                  <Logo onClick={() => onSelect('Home')}>
                    <Link to='/'>SUPERTRACK</Link>
                  </Logo>
                  <SubLogo onClick={() => onSelect('Home')}>
                    <Link to='/'>PARTNERS</Link>
                  </SubLogo>
                </LogoBlock>
                <MenusBlock>
                  {userMenus.map(m => (
                    <Menu
                      key={m.name}
                      active={m.name === menu}
                      onClick={() => onSelect(m.name)}
                    >
                      <Link to={`${m.link}`}>{m.name}</Link>
                    </Menu>
                  ))}
                  <Menu onClick={_handleLogout}>{'LOGOUT'}</Menu>
                  <MdOutlineShoppingCart size={24} color="#8a8a8a" style={{ cursor: 'pointer' }} onClick={_handleModal} _handleModal={_handleModal} />
                </MenusBlock>
              </Wrapper>
            </HeaderBlock>
            <Spacer />
          </>
        ) : (
          <>
            <HeaderBlock>
              <Wrapper>
                <LogoBlock>
                  <Logo onClick={() => onSelect('Home')}>
                    <Link to='/'>SUPERTRACK</Link>
                  </Logo>
                  <SubLogo onClick={() => onSelect('Home')}>
                    <Link to='/'>PARTNERS</Link>
                  </SubLogo>
                </LogoBlock>
                <MenusBlock>
                  {noUserMenus.map(m => (
                    <Menu
                      key={m.name}
                      active={m.name === menu}
                      onClick={() => onSelect(m.name)}
                    >
                      <Link to={`${m.link}`}>{m.name}</Link>
                    </Menu>
                  ))}
                </MenusBlock>
              </Wrapper>
            </HeaderBlock>
            <Spacer />
          </>
        )
      }
      {openModal && <CartModal _handleModal={_handleModal} />}

    </>
  );
};

export default Header;