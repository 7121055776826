import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { MdOutlineAddShoppingCart } from 'react-icons/md';
import Toast from '../common/Toast';
import { useDispatch } from 'react-redux';
import { addCart, deleteCart } from '../../store/actions';

const Wrapper = styled.div`
  padding: 10px;
`;

const Thumbnail = styled.img`
  width: 200px;
  height: 200px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const TextBox = styled.div`
  width: 180px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 15px;
`;

const SubText = styled.div`
  font-weight: 600;
  font-size: 12px;
`;

const ProductContainer = ({ product, level }) => {
  let price = product[level];
  price = price;
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const _handleClick = () => {
    setToast(!toast);
  };
  const _handleDelete = () => {
    dispatch(deleteCart(product));
  };
  return (
    <>
      {product && level && (
        <Wrapper>
          <Link to={`/product/detail/${product._id}`}>
            <Thumbnail src={product.img} />
          </Link>
          <Content>
            <TextBox>
              <Link to={`/product/detail/${product._id}`}>
                {product.product_name.length > 38 ? (
                  <Text>{product.product_name.slice(0, 38) + '...'}</Text>
                ) : (
                  <Text>{product.product_name}</Text>
                )}
                <SubText>{price.toLocaleString('ko-KR')}</SubText>
              </Link>
            </TextBox>
            <MdOutlineAddShoppingCart
              onClick={() => {
                _handleClick();
                dispatch(addCart(level, product, _handleDelete));
              }}
              size="24"
              cursor="pointer"
            />
          </Content>
          {toast && (
            <Toast _handleToast={_handleClick}>
              제품이 장바구니에 담겼습니다
            </Toast>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default ProductContainer;
