import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 5px;
  border: solid black 3px;
  border-radius: 10px;
  margin: 5px 0px;
`;

const Container = styled.textarea`
  width: 100%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: semibold;
  outline: none;
  border: none;
  resize: none;
  ::placeholder {
    color: #a8a8a8;
  }
`;

const TextBox = ({ height, type, name, value, placeholder, onChange }) => {
  return (
    <Wrapper>
      <Container style={{ height: height }} type={type} name={name} value={value} onChange={onChange} placeholder={placeholder || "내용을 입력해주세요."} />
    </Wrapper>
  );
};

export default TextBox;