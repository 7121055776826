import React from 'react';
import styled from 'styled-components';
import { BiSearch } from 'react-icons/bi';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 5px;
  border-bottom: solid black 3px;
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 180px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  outline: none;
  border: none;
  ::placeholder {
    color: gray;
  }
`;

const Searchbar = ({ onChange, placeholder }) => {
  const _handleChange = (e) => {
    onChange(e.target.value);
  }
  return (
    <Wrapper>
      <Input onChange={_handleChange} placeholder={placeholder || '상품명으로 검색'} />
      <BiSearch />
    </Wrapper>
  );
};

export default Searchbar;