import React, { useState } from 'react';
import qs from 'qs';
import styled from 'styled-components';
import swal from 'sweetalert2';
import axios from 'axios';
import {
  Document,
  Page,
  PDFViewer,
  PDFDownloadLink,
  BlobProvider,
} from '@react-pdf/renderer';
import Content from './Content';
import { pdfStyle } from '../../styles/pdfStyle';
import { useUserState } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Loading from '../common/Loading';

const Button = styled.div`
  background-color: #eeeeee;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor: pointer;
  :hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

const Preview = ({ inputs, onSelect, cart, sum, shipping }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUserState();
  const navigate = useNavigate();
  const myDoc = Template({ user, inputs, cart, sum, shipping });

  const _handleOrder = ({ user, blob }) => {
    swal
      .fire({
        text: '주문하시겠습니까?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#7499ab',
        cancelButtonColor: '#a8a8a8',
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            setIsLoading(true);
            const b64 = await convertBlobToBase64(blob);
            const data = await qs.stringify({
              user_id: user._id,
              company_name: user.company_name,
              pdfUrl: b64,
              price: sum,
            });
            const res = await axios.post(`/api/orders/orderlist`, data, {
              headers: {
                'Content-type': 'application/x-www-form-urlencoded',
              },
            });
            if (res.status === 200) {
              const order_id = res.data.order.order_id;
              try {
                const emailResponse = await axios.post(`/mail`, {
                  user_email: `${user.user_email}`,
                  manager_name: `${user.manager_name}`,
                  company_name: `${user.company_name}`,
                  manager_contact: `${user.manager_contact}`,
                  download: `${b64}`,
                  order_id: `${order_id}`,
                });
                console.log(emailResponse);
                setIsLoading(false);
                swal
                  .fire({
                    title: '주문이 완료되었습니다!',
                    text: '발주서는 마이페이지에서 확인 및 다운로드가 가능합니다.',
                    icon: 'success',
                    showConfirmButton: true,
                    confirmButtonColor: '#a8a8a8',
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      try {
                        navigate('/mypage');
                        onSelect('MYPAGE');
                        window.location.reload();
                      } catch {
                        swal.fire({
                          position: 'top-end',
                          width: 400,
                          text: '오류가 발생했습니다.',
                          icon: 'error',
                          showConfirmButton: false,
                          timer: 1000,
                        });
                      }
                    }
                  });
              } catch (err) {
                console.log('이메일오류', err);
                swal.fire({
                  position: 'top-end',
                  width: 400,
                  text: '오류가 발생했습니다.',
                  icon: 'error',
                  showConfirmButton: false,
                  timer: 1000,
                });
              }
            }
          } catch (e) {
            swal.fire({
              position: 'top-end',
              width: 400,
              text: '오류가 발생했습니다.',
              icon: 'error',
              showConfirmButton: false,
              timer: 1000,
            });
          }
        }
      });
  };

  return (
    <div
      style={{
        width: '800px',
        height: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <BlobProvider document={myDoc}>
        {({ blob }) => {
          return (
            <>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button>
                  <PDFDownloadLink
                    document={
                      <Template
                        user={user}
                        inputs={inputs}
                        cart={cart}
                        sum={sum}
                        shipping={shipping}
                      />
                    }
                    fileName="발주서.pdf"
                  >
                    {({ loading }) => (loading ? '로딩중...' : '다운로드')}
                  </PDFDownloadLink>
                </Button>
                <Button
                  style={{ backgroundColor: '#a8a8a8', marginLeft: '10px' }}
                  onClick={() => _handleOrder({ user: user, blob: blob })}
                >
                  주문하기
                </Button>
              </div>
              <PDFViewer
                showToolbar={false}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <Template
                  user={user}
                  inputs={inputs}
                  cart={cart}
                  sum={sum}
                  shipping={shipping}
                />
              </PDFViewer>
            </>
          );
        }}
      </BlobProvider>
      {isLoading && <Loading />}
    </div>
  );
};
// Create Document Component
const Template = ({ user, inputs, cart, sum, shipping }) => {
  return (
    <Document language="kr">
      <Page size="A4" style={pdfStyle.page}>
        <Content
          user={user}
          inputs={inputs}
          cart={cart}
          sum={sum}
          shipping={shipping}
        />
      </Page>
    </Document>
  );
};

export default Preview;
