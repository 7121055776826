import { View, Text } from '@react-pdf/renderer';
import { pdfStyle } from '../../styles/pdfStyle';

const Content = ({ user, inputs, cart, sum, shipping }) => {
  function leftPad(value) {
    if (value >= 10) {
      return value;
    }
    return `0${value}`;
  }
  function toStringByFormatting(source, delimiter = '-') {
    const year = source.getFullYear();
    const month = leftPad(source.getMonth() + 1);
    const day = leftPad(source.getDate());
    return [year, month, day].join(delimiter);
  }
  const date = toStringByFormatting(new Date());
  return (
    <View style={pdfStyle.container}>
      {user && cart && sum && (
        <>
          <Text style={pdfStyle.title_text}>발 주 서</Text>

          {/* 1번 */}
          <View
            style={[pdfStyle.line_wrapper_top, { backgroundColor: '#eeeeee' }]}
          >
            <View style={[pdfStyle.line_container, { width: '50%' }]}>
              <Text>수 신</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '50%' }]}>
              <Text>발 신</Text>
            </View>
          </View>
          <View style={pdfStyle.line_wrapper}>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>상호</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '35%' }]}>
              <Text>(주)슈퍼트랙</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>상호</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '35%' }]}>
              <Text>{user.company_name}</Text>
            </View>
          </View>
          <View style={pdfStyle.line_wrapper}>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>담당자</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '35%' }]}>
              <Text>박경우 팀장</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>담당자</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '35%' }]}>
              <Text>{user.manager_name}</Text>
            </View>
          </View>
          <View style={pdfStyle.line_wrapper}>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>전화</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '35%' }]}>
              <Text>02-539-7778</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>전화</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '35%' }]}>
              <Text>{user.manager_contact}</Text>
            </View>
          </View>
          <View style={[pdfStyle.line_wrapper, { marginBottom: '20px' }]}>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>이메일</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '35%' }]}>
              <Text>order@onethecode.com</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '15%' }]}>
              <Text>이메일</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '35%' }]}>
              <Text>{user.user_email}</Text>
            </View>
          </View>

          {/* 2번 */}
          <View style={[pdfStyle.line_wrapper_top, { marginBottom: '20px' }]}>
            <View
              style={[
                pdfStyle.line_container,
                {
                  backgroundColor: '#eeeeee',
                  width: '15%',
                  paddingTop: '20px',
                },
              ]}
            >
              <Text>배송 정보</Text>
            </View>
            <View
              style={[
                pdfStyle.line_container_right,
                {
                  paddingLeft: '10px',
                  alignItems: 'flex-start',
                  width: '85%',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                },
              ]}
            >
              <Text style={pdfStyle.name_text}>수령자: {inputs.name}</Text>
              <Text style={pdfStyle.name_text}>연락처: {inputs.phoneNo}</Text>
              <Text style={pdfStyle.name_text}>주소: {inputs.address}</Text>
            </View>
          </View>

          {/* 1번 */}
          <View style={{ width: '100%' }}>
            <Text style={{ textAlign: 'right' }}>(단위 : 원)</Text>
          </View>

          <View
            style={[pdfStyle.line_wrapper_top, { backgroundColor: '#eeeeee' }]}
          >
            <View style={[pdfStyle.line_container, { width: '50%' }]}>
              <Text>품명</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '10%' }]}>
              <Text>수량</Text>
            </View>
            <View style={[pdfStyle.line_container, { width: '20%' }]}>
              <Text>단가 (VAT)</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '20%' }]}>
              <Text>금액 (VAT)</Text>
            </View>
          </View>
          {cart.map((c) => {
            return (
              <View style={pdfStyle.line_wrapper}>
                <View style={[pdfStyle.line_container, { width: '50%' }]}>
                  <Text>{c.product_name}</Text>
                </View>
                <View style={[pdfStyle.line_container, { width: '10%' }]}>
                  <Text>{c.count}</Text>
                </View>
                <View style={[pdfStyle.line_container, { width: '20%' }]}>
                  <Text>{(c.price / c.count).toLocaleString('ko-KR')}</Text>
                </View>
                <View style={[pdfStyle.line_container_right, { width: '20%' }]}>
                  <Text>{c.price.toLocaleString('ko-KR')}</Text>
                </View>
              </View>
            );
          })}
          <View style={pdfStyle.line_wrapper}>
            <View
              style={[
                pdfStyle.line_container,
                { backgroundColor: '#eeeeee', width: '60%' },
              ]}
            >
              <Text>배송비</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '40%' }]}>
              <Text>
                {sum < 100000 ? shipping.toLocaleString('ko-KR') : '무료'}
              </Text>
            </View>
          </View>
          <View style={pdfStyle.line_wrapper}>
            <View
              style={[
                pdfStyle.line_container,
                { backgroundColor: '#eeeeee', width: '60%' },
              ]}
            >
              <Text>공급가액</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '40%' }]}>
              <Text>
                {sum < 100000
                  ? Math.round(((sum + shipping) / 11) * 10).toLocaleString(
                      'kr-KR',
                    )
                  : Math.round((sum / 11) * 10).toLocaleString('kr-KR')}
              </Text>
            </View>
          </View>
          <View style={pdfStyle.line_wrapper}>
            <View
              style={[
                pdfStyle.line_container,
                { backgroundColor: '#eeeeee', width: '60%' },
              ]}
            >
              <Text>부가세 (VAT)</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '40%' }]}>
              <Text>
                {sum < 100000
                  ? Math.round((sum + shipping) / 11).toLocaleString('kr-KR')
                  : Math.round(sum / 11).toLocaleString('kr-KR')}
              </Text>
            </View>
          </View>

          <View style={pdfStyle.line_wrapper}>
            <View
              style={[
                pdfStyle.line_container,
                { backgroundColor: '#eeeeee', width: '60%' },
              ]}
            >
              <Text>총 금액 (VAT 포함)</Text>
            </View>
            <View style={[pdfStyle.line_container_right, { width: '40%' }]}>
              <Text>
                {sum < 100000
                  ? (sum + shipping).toLocaleString('kr-KR')
                  : sum.toLocaleString('kr-KR')}
              </Text>
            </View>
          </View>

          <View style={[pdfStyle.line_wrapper, { marginBottom: '20px' }]}>
            <View
              style={[
                pdfStyle.line_container_right,
                {
                  height: '60px',
                  paddingLeft: '10px',
                  alignItems: 'flex-start',
                  width: '100%',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                },
              ]}
            >
              <Text>{inputs.other}</Text>
            </View>
          </View>
          <Text>{date}</Text>
        </>
      )}
    </View>
  );
};

export default Content;
