import React, { useState } from 'react';
import styled from 'styled-components';
import { BiPlus, BiMinus } from 'react-icons/bi';

const Box = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const Input = styled.input`
  width: 30px;
  border: none;
  outline: none;
  font-weight: 500;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  `;

const Counter = ({ count, onChange }) => {
  const _onMinus = () => {
    if (count > 1)
      onChange(count - 1);
  };
  const _onPlus = () => {
    if (count < 999)
      onChange(count + 1);
  };
  const _handleChange = (e) => {
    onChange(parseInt(e.target.value));
  }

  return (
    <div>
      <Box>
        <BiMinus onClick={_onMinus} style={{ cursor: "pointer" }} />
        <Input type="number" value={count || ''} onChange={_handleChange}
          style={{ width: "30px", textAlign: "center" }}></Input>
        <BiPlus onClick={_onPlus} style={{ cursor: "pointer" }} />
      </Box>
    </div>
  );
};

export default Counter;