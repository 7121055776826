import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '../components/common/Button';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100vh - 160px);
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 10px;
`;

const JoinConfirmPage = () => {
  return (
    <Container>
      <Text>가입 신청이 완료되었습니다.</Text>
      <Text>승인이 완료되면 입력하신 이메일 주소로 승인 완료 메일이 전송됩니다.</Text>
      <Link to='/' style={{ position: "absolute", bottom: "130px" }}>
        <Button>BACK TO HOME</Button>
      </Link>
    </Container>
  );
};

export default JoinConfirmPage;