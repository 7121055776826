import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import Input from '../components/common/Input';
import axios from 'axios';
import { useUserDispatch } from '../contexts/UserContext';

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100vh - 160px);
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 80px;
`;

const onLoginSuccess = async ({ res, dispatch }) => {
  const { _id, user_email, level, company_name, manager_contact, manager_name } = await res.data;
  dispatch({
    type: 'LOGIN',
    _id: _id,
    user_email: user_email,
    level: level,
    company_name: company_name,
    manager_name: manager_name,
    manager_contact: manager_contact
  });
}

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useUserDispatch();

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });

  const { email, password } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const _handleLogin = async (e) => {
    e.preventDefault();

    const dataToSubmit = {
      user_email: inputs.email,
      user_password: inputs.password,
    };

    try {
      const res = await axios.post('/api/users/login', dataToSubmit);
      onLoginSuccess({ res, dispatch });
      navigate('/');
    }
    catch (err) {
      if (err.response.status === 500)
        alert('가입 승인 대기 중입니다.');
      else if (err.response.status === 501)
        alert('가입되지 않은 이메일입니다');
      else if (err.response.status === 400)
        alert('비밀번호가 일치하지 않습니다.');
    }
  };

  return (
    <Container>
      <Text>LOGIN</Text>
      <Input
        type="email"
        name="email"
        value={email}
        onChange={onChange}
        placeholder="EMAIL"
      />
      <Input
        type="password"
        name="password"
        value={password}
        onChange={onChange}
        placeholder="PASSWORD"
      />
      <Button
        width="400"
        onClick={_handleLogin}
        style={{ padding: "20px", backgroundColor: 'black', color: 'white' }}
      >
        LOGIN
      </Button>
      <Link to='/join' style={{ marginTop: "10px" }}>
        <Button width='400'>JOIN</Button>
      </Link>
    </Container>
  );
};

export default LoginPage;
